import axios from 'axios'
export default {
    namespaced: true,
    state: {
        boroughList: {},
        boroughTotalPage: ''
        //  editServicetValue:{}
    },
    mutations: {
        SET_BOROUGH_LIST( state, data){
            state.boroughList = data
        },
        SET_BOROUGH_TOTAL_PAGE( state, data ){
            state.boroughTotalPage = data
        }
      
    },
    actions: {
         /* eslint-disable */
        async GET_BORUGH_LIST({ commit, state }, query) {
            // const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}list_of_borough`,
                params:{
                    size: 15
                }
               
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options)
             commit('SET_BOROUGH_LIST', data.data.data)
             console.log(data.data.data)
             commit('SET_BOROUGH_TOTAL_PAGE', data.data.totalPages)
        },
        async ADD_BOROUGH({ commit }, payload){
            try {

                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const  add_borough = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}add_borough`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
             console.log(add_borough);

            } catch (error) {
                console.log(error);
            }
            

        },
      
        async DELETE_BOROUGH({ commit }, payload){
            // console.log(payload)
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                const deleteBorough = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}delete_borough/${ payload.id }`,
                
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                console.log(deleteBorough)

            } catch (error) {
                console.log(error)
            }
        } ,
       
        async UPDATE_BORUGH({ commit }, payload){
            try{
                 const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}update_borough/${ payload.id }`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                 console.log(update)

            }catch(error){
                console.log(error)
            }
        },
       
    },
    getters: {
        getClientById:(state) => (id) =>{
            return state.clientList.find( list => list.id === id )
        } 
        
    }

}