import axios from 'axios'
export default {
    namespaced: true,
    state: {
        destributionLogList: {},
        destributionLogTotalPage: ''
        //  editServicetValue:{}
    },
    mutations: {
        SET_DESTRIBUTION_LOG_LIST( state, data){
            state.destributionLogList = data
        },
        SET_DESTRIBUTION_LOG_TOTAL_PAGE( state, data ){
            state.destributionLogTotalPage = data
        }
      
    },
    actions: {
         /* eslint-disable */
        async GET_DESTRIBUTION_LOG_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}distribution/list_of_distribution`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options);
             commit('SET_DESTRIBUTION_LOG_LIST', data.data.data);
             console.log(data);
             commit('SET_DESTRIBUTION_LOG_TOTAL_PAGE', data.data.totalPages);
        },
        

       
    },
    getters: {
    
        
    }

}