<template>
  <div class="text-center " style="width:100%">

    <v-snackbar v-model="show" rounded="0"  color="" :timeout="timeout" transition="scroll-x-transition"   >
      We are using Cookies! For more information visit this <a style="text-decoration: none; color: #f28204;" href="https://newhamcommunityproject.org/privacypolicy.html">page</a> Cookie policy.

      <template v-slot:actions >

        <v-btn color="black" style="background: white;" rounded="0" variant="text" @click="show = false">
          ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: 'initialVue',
  data: () => ({
    snackbar: false,
    // text: '',
    timeout: 9000000000,
  }),
  props: {
    visible: {
      type: Boolean,
    },
    text: {
      type: String,
      default: 'You must login first'
    },
    button: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,

    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
<style>

</style>