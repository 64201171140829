<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" sm="12" class="pa-0">
        <v-card class="mx-auto pa-lg-10 pa-sm-0 " rounded="0" elevation="0" min-height="600" color="">
          <!-- <p>{{ type }}</p> -->
          <div class="text-center" style="width: 100%">
            <v-img :src="logo" class="ma-auto mt-" height="100" width="210" cover=""></v-img>
          </div>
          <v-window v-model="step" :touch="false">
            <v-window-item :value="1">
              <v-card-title class="text-h5  justify-space-between text-center">
                Personal Details
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="3" sm="3">
                    <v-autocomplete :menu-props="{ maxHeight: 300 }" variant="underlined" h density="comfortable"
                      v-model="titles" dense :items="Title" item item-title="name" item-value="id" label="Title">
                    </v-autocomplete>
                    <span v-if="v$.titles.$error" style="color: red">
                      {{ v$.titles.$errors[0].$message }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="5" sm="5">
                    <v-text-field label="First Name" density="compact" v-model="firstName" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.firstName.$error" style="color: red">
                      {{ v$.firstName.$errors[0].$message }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <v-text-field label="Last Name" density="compact" v-model="secondName" variant="underlined">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-text-field label="Phone" density="compact" v-model="phone" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.phone.$error" style="color: red">
                      {{ v$.phone.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field label="Email" density="compact" v-model="email" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.email.$error" style="color: red">
                      {{ v$.email.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete variant="underlined" density="compact" v-model="type" clearable :items="Role"
                      item-title="type" item-value="id" label="Type">
                    </v-autocomplete>
                    <span v-if="v$.type.$error" style="color: red">
                      {{ v$.type.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <div v-if="type == 3">
                  <!-- <v-row class="">
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field label="Dependent Name" density="compact" v-model="DependentName" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.DependentName.$error" style="color: red">
                      {{ v$.DependentName.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row> -->
                  <v-row class="">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Registered Member Email" density="compact" v-model="DepEmail"
                        variant="underlined">
                      </v-text-field>
                      <span v-if="v$.DepEmail.$error" style="color: red">
                        {{ v$.DepEmail.$errors[0].$message }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Registered Member Phone" density="compact" v-model="DepPhone"
                        variant="underlined">
                      </v-text-field>
                      <span v-if="v$.DepPhone.$error" style="color: red">
                        {{ v$.DepPhone.$errors[0].$message }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <p>OR</p>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Registered Member ID" density="compact" v-model="DepId" variant="underlined">
                      </v-text-field>
                      <span v-if="v$.DepId.$error" style="color: red">
                        {{ v$.DepId.$errors[0].$message }}
                      </span>
                    </v-col>
                  </v-row>
                </div>

                <v-row class="" v-if="type != 3">
                  <v-col cols="12" md="12">
                    <v-autocomplete variant="underlined" density="compact" v-model="heveDependent" clearable
                      :items="isDependent" item-title="title" item-value="val"
                      label="Do you have dependent-living in the UK ?">
                    </v-autocomplete>
                    <!-- <span v-if="v$.NoKids.$error" style="color: red">
                      {{ v$.NoKids.$errors[0].$message }}
                    </span> -->
                  </v-col>
                </v-row>
                <div v-if="heveDependent">
                  <v-row class="">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Dependent Name" density="compact" v-model="dependentName"
                        variant="underlined">
                      </v-text-field>
                      <span v-if="v$.dependentName.$error" style="color: red">
                        {{ v$.dependentName.$errors[0].$message }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Dependent Phone" density="compact" v-model="dependentPhone"
                        variant="underlined">
                      </v-text-field>
                      <span v-if="v$.dependentPhone.$error" style="color: red">
                        {{ v$.dependentPhone.$errors[0].$message }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Dependent Email" density="compact" v-model="dependentemail"
                        variant="underlined">
                      </v-text-field>
                      <span v-if="v$.dependentemail.$error" style="color: red">
                        {{ v$.dependentemail.$errors[0].$message }}
                      </span>
                    </v-col>
                  </v-row>
                </div>
                <v-row class="" v-if="type != 3">
                  <v-col cols="12" md="12">
                    <v-autocomplete variant="underlined" density="compact" v-model="NoKids" clearable :items="CHnumber"
                      label="Number Of kids Living with you in UK">
                    </v-autocomplete>
                    <span v-if="v$.NoKids.$error" style="color: red">
                      {{ v$.NoKids.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row v-if="NoKids == 'Expected'">
                  <v-col cols="12" md="12">
                    <div class="date d-flex pl-3" style="">
                      <p class="mr" style="color:#7d7d86;">Expected Date(Aprox):</p>
                      <input type="date" class="dt" v-model="ExpectedDate" />
                    </div>
                    <span v-if="v$.ExpectedDate.$error" style="color: red">
                      {{ v$.ExpectedDate.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="2">
              <v-card-title class="text-h5  justify-space-between text-center">
                Contact Details
                <p style="font-size: 9px; color: red;">{{ grp_err_text }}</p>
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-autocomplete clearable label="Post Code " density="compact" v-model="zib" v-model:search="search"
                      :items="postCodeList" variant="underlined">
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Search for
                            <strong>Post Code</strong>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <span v-if="v$.zib.$error" style="color: red">
                      {{ v$.zib.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-autocomplete label="Address" clearable aria-disabled="" density="compact" v-model="address"
                      :items="addressList" item-title="line_1" variant="underlined">
                    </v-autocomplete>
                    <span v-if="v$.address.$error" style="color: red">
                      {{ v$.address.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-autocomplete label="Building No" clearable aria-disabled="" density="compact" v-model="country"
                      :items="buildingList" variant="underlined">
                    </v-autocomplete>
                    <!-- <v-text-field label="Building No" density="compact" v-model="country" variant="underlined">
                    </v-text-field> -->
                    <span v-if="v$.country.$error" style="color: red">
                      {{ v$.country.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <!-- <v-row class="">
                  <v-col cols="12" md="12">
                    <v-textarea variant="underlined" label="Address" v-model="address" auto-grow outlined rows="2"
                      row-height="25" shaped>
                    </v-textarea>
                    <span v-if="v$.address.$error" style="color: red">
                      {{ v$.address.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row> -->

                <v-row class="">
                  <v-col cols="12" md="12" v-if="borough">
                    <v-text-field label="Borough" disabled density="compact" :model-value="borough"
                      variant="underlined">
                    </v-text-field>
                    <!-- <v-autocomplete variant="underlined" density="compact" v-model="borough" clearable :items="Borough"
                      item-title="name" item-value="id" label="">
                    </v-autocomplete> -->
                    <!-- <span v-if="v$.borough.$error" style="color: red">
                      {{ v$.borough.$errors[0].$message }}
                    </span> -->
                  </v-col>
                </v-row>


              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-title class="text-h5  justify-space-between text-center">
                Education Details
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-text-field label="Student ID" density="compact" v-model="studentId" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.studentId.$error" style="color: red">
                      {{ v$.studentId.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    
                    <v-autocomplete variant="underlined"  density="compact" v-model:search="searchUnsty"
                      v-model="university" clearable :items="universityLis" item-title="name" item-value="id"
                      label="University">
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Search for
                            <strong>University</strong>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <span v-if="v$.university.$error" style="color: red">
                      {{ v$.university.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="12">
                    <div class="date d-flex pt-3" style="">
                      <p class="mr-2" style="color:#7d7d86;">Arrived date:</p>
                      <input type="date" class="dt" v-model="ArrivedDate" />
                    </div>
                    <span v-if="v$.ArrivedDate.$error" style="color: red">
                      {{ v$.ArrivedDate.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="12">
                    <div class="date d-flex" style="">
                      <p class="mr-2 " style="color:#7d7d86;">Course Start Date:</p>
                      <input type="date" class="dt" v-model="startDate" />
                    </div>
                    <span v-if="v$.startDate.$error" style="color: red">
                      {{ v$.startDate.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="12">
                    <div class="date d-flex" style="">
                      <p class="mr-2" style="color:#7d7d86;">Course End Date:</p>
                      <!-- <v-btn flat="" >
                      <span>date</span> -->
                      <input type="date" class="dt" v-model="EndDate" />
                      <!-- <input placeholder="Type Date" type="date" onfocus="(this.type = 'date')"  id="date"> -->
                      <!-- </v-btn> -->

                      <!-- <input id="flatpickr" v-model="EndDate"  /> -->
                      <!-- <flat-pickr v-model="EndDate" style="background: red" placeholder="Select date"></flat-pickr> -->
                    </div>
                    <!-- <div class="form-group ">
                      <label for="example-date-input" class="col-2 col-form-label">Course End Date:</label>
                      <div class="col-10">
                        <input class="form-control" v-model="EndDate" placeholder="date" type="date" id="example-date-input">
                      </div>
                    </div> -->
                    <span v-if="v$.EndDate.$error" style="color: red">
                      {{ v$.EndDate.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="4">
              <v-card-title class="text-h5  justify-space-between text-center">
                Food Details
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <!--  Food Details -->
                    <v-autocomplete variant="underlined" density="compact" v-model="deitryRequirment" clearable
                      :items="dietReq" item-title="name" item-value="id" label="Dietary Requirements">
                    </v-autocomplete>
                    <span v-if="v$.deitryRequirment.$error" style="color: red">
                      {{ v$.deitryRequirment.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <!--  Food Details -->
                    <v-autocomplete variant="underlined" density="compact" v-model="eggs" clearable :items="eggList"
                      item-title="name" item-value="id" label="Do you eat Eggs?">
                    </v-autocomplete>
                    <span v-if="v$.eggs.$error" style="color: red">
                      {{ v$.eggs.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <!--  Food Details -->
                    <v-autocomplete variant="underlined" density="compact" v-model="collectionDay" clearable
                      :items="prefCollection" label="Preferred Collection Day">
                    </v-autocomplete>
                    <span v-if="v$.collectionDay.$error" style="color: red">
                      {{ v$.collectionDay.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <div style="font-family: Montserrat, sans-serif !important;font-weight: 600;">

                    <p> <input type="checkbox" v-model="select" id="TC" name="TC" value="TC">
                      I hereby agree that this data will be stored and processed for the purpose of establishing
                      contact. I am aware that I can revoke my consent at any time by contacting us.
                    </p>
                  </div>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="5">
              <v-card-title class="text-h5  justify-space-between text-center">
                Kid's Details
              </v-card-title>
              <v-card-text v-if="!isNaN(this.NoKids)">

                <v-row class="mb-3" v-for="hh in parseInt(this.NoKids)" :key="hh">
                  <v-col cols="12" md="6" sm="6">
                    <!-- <p>{{hh}}</p> -->
                    <v-text-field :label="'Child Name' + ' ' + hh" density="compact" hide-details=""
                      v-model="child[hh - 1].name" variant="underlined">
                    </v-text-field>
                    <!-- <span v-if="v$.zib.$error" style="color: red">
                      {{  v$.zib.$errors[0].$message  }}
                    </span> -->
                  </v-col>
                  <v-col cols="12" md="6" sm="6" style="align-self: center">
                    <div class="date d-flex" style="">
                      <p class="mr" style="color:#7d7d86;">DOB:</p>
                      <input type="date" class="dt" v-model="child[hh - 1].dob" />
                    </div>
                    <!-- <div v-for="error in v$.collection.$each.$child.$errors[index].name" :key="error">
                      {{  error.$message  }}
                    </div> -->

                  </v-col>
                </v-row>
                <span v-if="errValue" style="color: red">
                  {{ errValue }}
                </span>
              </v-card-text>

            </v-window-item>
          </v-window>

          <!-- <v-divider></v-divider> -->

          <v-card-actions>
            <!-- <v-spacer></v-spacer> -->


            <v-btn v-if="step > 1" text @click="goBack()" rounded="0" height="48" width="80" variant="outlined">
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="step < 4" color="white" style="background: #323232;" height="48" width="80" plain depressed
              rounded="0" @click="nextwindow()">
              {{ nextbtnName }}
            </v-btn>
            <v-btn v-if="step == 4" color="white" style="background: #323232;" height="48" width="80" plain depressed
              rounded="0" @click="submitMemeber()">
              Submit
            </v-btn>
            <v-btn v-if="step == 5" color="white" style="background: #323232;" height="48" width="80" plain rounded="0"
              depressed @click="nextwindow33()">
              Next
            </v-btn>
          </v-card-actions>
          <snackbarInitail :visible="snackbarIn" @close="snackbarIn = false" />
          <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />
          <dialogVue :visible="dialogBox" @close="dialogBox = false" @yes="dialogBox = false, step++" @no="noGroup" />

        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="6" sm="0" xs>

      </v-col> -->
    </v-row>
  </v-container>
</template>

  
<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex'
import useVuelidate from "@vuelidate/core";
import { helpers, required, numeric, decimal, maxLength, email } from "@vuelidate/validators";
import snackbarVue from '@/components/snackbar';
import snackbarInitail from '@/components/snackbarInitail';
import dialogVue from '@/components/dialog.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { reactive } from 'vue';

import axios from 'axios'
export default {
  name: 'addmemeberVue',
  components: {
    snackbarVue,
    snackbarInitail,
    dialogVue,
    flatPickr
  },

  data() {
    return {
      v$: useVuelidate(),
      errValue: '',
      snackbarIn: true,
      snackbar: false,
      dialogBox: false,
      mssg: '',
      grp_err_text: null,
      snackColor: 'green',
      nextbtnName: 'Next',
      select: '',
      logo: require('@/assets/newham.jpeg'),
      step: 1,
      titles: '',
      firstName: '',
      secondName: '',
      phone: '',
      email: '',
      type: '',
      // DependentName: null,
      NoKids: null,
      ExpectedDate: '',
      area: '',
      country: '',
      borough: '',
      address: '',
      zib: '',
      studentId: '',
      university: '',
      dialog: false,
      // ArrivedDate: new Date().toISOString().substr(0, 10),
      // startDate: new Date().toISOString().substr(0, 10),
      // EndDate: new Date().toISOString().substr(0, 10),

      DepEmail: '',
      DepPhone: '',
      DepId: '',

      ArrivedDate: '',
      startDate: '',
      EndDate: '',
      deitryRequirment: '',
      eggs: '',
      collectionDay: '',
      Title: ['Mr', 'Mrs', 'Miss', 'Ms',],
      CHnumber: ['None', '1', '2', '3', '4', '5', 'Expected'],
      areas: [
        {
          id: 0,
          name: 'city',
        },
        {
          id: 1,
          name: 'Town',
        }
      ],
      dietReq: [
        {
          id: 1,
          name: 'Halal'
        },
        {
          id: 2,
          name: 'Non-Halal'
        },
        {
          id: 3,
          name: 'Vegiterian'
        },
      ],
      eggList: [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 0,
          name: 'No'
        },
      ],
      Role: [
        {
          id: 1,
          type: 'Student'
        },
        {
          id: 3,
          type: 'Dependent'
        },
        // {
        //   id: 3,
        //   type: 'Student with Children'
        // },
        {
          id: 4,
          type: 'PSW'
        },
        {
          id: 5,
          type: 'TIER2'
        },
        {
          id: 6,
          type: 'Undocumented'
        },
        {
          id: 7,
          type: 'Benefits'
        },
      ],
      Borough: [{ id: 1, name: '' }],
      univer: [{ id: 1, name: '' }],
      child: [],
      heveDependent: null,
      isDependent: [
        {
          val: true,
          title: 'Yes'
        },
        {
          val: false,
          title: 'No'
        }
      ],
      dependentName: null,
      dependentPhone: '',
      dependentemail: '',
      prefCollection: ['TUESDAY AFTERNOON (STRICTLY Ladies with Children ONLY', 'FRIDAY AFTERNOON (STRICTLY Ladies with Children ONLY)'],
      search: '',
      searchUnsty: '',
      buildingList: [],
      formAddress: [],
      memberType: ''


    }
  },

  validations() {
    return {
      titles: { required },
      firstName: { required },
      // secondName: { required },
      phone: { required, numeric, maxLength: maxLength(12) },
      email: { required, email },
      type: { required },
      zib: { required },
      // DependentName: { required },
      NoKids: { required },
      ExpectedDate: { required },
      // area: { required },
      country: { required },
      borough: { required },
      address: { required },


      studentId: { required },
      university: { required },
      ArrivedDate: { required },
      startDate: { required },
      EndDate: { required },

      deitryRequirment: { required },
      eggs: { required },
      collectionDay: { required },

      DepEmail: { required, email },
      DepPhone: { required, numeric, maxLength: maxLength(12) },
      DepId: { required },

      dependentemail: { required, email },
      dependentPhone: { required, numeric, maxLength: maxLength(12) },
      dependentName: { required },

      // child: {
      //   $each: helpers.forEach({
      //     name: {
      //       require
      //     },
      //     dob: {
      //       require
      //     }
      //   })
      // }

    }
  },

  watch: {
    type(newVl, oldVl) {
      if (newVl == 3) {
        this.nextbtnName = 'submit'
      } else {
        this.nextbtnName = 'next'

      }
      if (newVl) {
        const memType = this.Role.find(o => o.id == newVl)
        this.memberType = memType.type
      }
    },
    search(val) {
      this.GET_POST_CODE_LIST(val);

    },
    searchUnsty(val) {
      if (val) {
        this.GET_UNIVERSITY_LIST({ name: val, size: 6 }).then(() => {
          this.univer = this.universityLis;
        })
      }

      // console.log(val)
    },
    zib(val) {
      this.address = ''
      this.GET_ADDRESS_LSIT(val)
      this.borough = this.boroughFromAddress
      // console.log(val)
    },
    address(val) {
      const add = this.$store.getters["form/getAddresByline_1"](val);
      // console.log(add)
      this.formAddress = add[0]
      // console.log(this.formAddress)
      this.buildingList = [];
      this.country = ''
      add.forEach(el => {
        this.buildingList.push(el.building_number)
        // console.log(this.buildingList)
      })
    },
    step(val) {
      if (val == 2) {
        this.grp_err_text = null
      }
    },
    NoKids(val) {
      // this.ExpectedDate = ''
      if (this.val != 'Expected') {
        this.ExpectedDate = ''
      }
    }



  },
  computed: {
    ...mapState('member', ['memberRole', 'memberEx', 'duplicateCheck']),
    ...mapState('borough', ['boroughList']),
    ...mapState('university', ['universityLis']),
    ...mapState('form', ['postCodeList', 'addressList', 'boroughFromAddress']),

    // computed: {
    //   currentTitle() {
    //     switch (this.step) {
    //       case 1: return 'Sign-up'
    //       case 2: return 'Create a password'
    //       default: return 'Account created'
    //     }
    //   },
    // },
    // show: {
    //   get() {
    //     return this.visible
    //   },
    //   set(value) {
    //     if (!value) {
    //       this.$emit('close')
    //     }
    //   }
    // }
  },
  methods: {
    ...mapActions('member', ['GET_MEMBER_LIST', 'GET_MEMBER_ROLE', 'ADD_MEMBER', 'GET_MEMBER_BY_ID', 'UPDATE_MEMBER', 'CHECK_MEMBER_EXIST', 'UPDATE_DEPENTENT', 'CHECK_DUPLICATE_PHONE_EMAIL']),
    ...mapActions('borough', ['GET_BORUGH_LIST']),
    ...mapActions('university', ['GET_UNIVERSITY_LIST']),
    ...mapActions('form', ['GET_POST_CODE_LIST', 'GET_ADDRESS_LSIT']),
    // test() {
    //   this.mssg = 'Please Add Registered Student Details';
    //   this.snackColor = 'error';
    //   return this.snackbar = true
    // },
    nextwindow33() {
      // console.log(this.child);
      // this.child.forEach(el =>{
      //   console.log(el.name, el.dob)
      // })
      for (let i = 0; i < this.child.length; i++) {
        // console.log(this.child[i])
        if (this.child[i].name == '' || this.child[i].dob == '') {
          // console.log('please Add All values')
          return this.errValue = 'please Add All values';
        }
      }
      this.errValue = ''
      this.step = 2
      this.Borough = this.boroughList;
      // this.univer = this.universityLis;
      // this.v$.child.$validate();
      // console.log( this.v$)

    },
    async nextwindow() {
      // console.log(this.v$)
      if (this.step == 1) {
        this.v$.$reset();
        this.v$.titles.$validate();
        this.v$.firstName.$validate();
        this.v$.phone.$validate();
        this.v$.email.$validate();
        this.v$.type.$validate();
        //  this.v$.$validate();
        if (this.type == 3) {
          // this.v$.DependentName.$validate();
          if (!this.DepEmail && !this.DepPhone && !this.DepId) {
            this.mssg = 'Please Add Registered Student Details';
            this.snackColor = 'error';
            return this.snackbar = true
          }
          if (this.DepEmail || this.DepPhone) {
            this.v$.DepEmail.$validate();
            this.v$.DepPhone.$validate();
          }
          else {
            this.v$.DepId.$validate();

          }
        }
        if (this.NoKids == 'Expected') {
          this.v$.ExpectedDate.$validate();
        }
        if (this.heveDependent) {
          this.v$.dependentemail.$validate();
          this.v$.dependentPhone.$validate();
          this.v$.dependentName.$validate();

        }


        // console.log(this.v$)
        // this.v$.$validate()
        //  console.log(this.v$.$errors)
        if (this.v$.$errors.length == 0) {
          if (this.type == 3) {
            const query = {
              phne: this.DepPhone,
              id: this.DepId,
              email: this.DepEmail
            }
            await this.CHECK_MEMBER_EXIST(query).then(() => {
              console.log(this.memberEx)
              if (this.memberEx == null) {
                this.snackColor = 'error';
                this.mssg = 'No Student found'
                this.snackbar = true;
              } else if (this.memberEx.dependentemail || this.memberEx.dependentphone) {
                this.snackColor = 'error';
                this.mssg = 'Student already has an Dependent'
                this.snackbar = true;
              } else {
                const payload = {
                  id: this.memberEx.id,
                  dependentname: this.firstName + ' ' + this.secondName,
                  dependentphone: this.phone,
                  dependentemail: this.email
                }
                console.log(payload)
                this.UPDATE_DEPENTENT(payload).then(() => {
                  this.titles = ''
                  this.firstName = ''
                  this.secondName = ''
                  this.phone = ''
                  this.email = ''
                  this.DepPhone = ''
                  this.DepEmail = ''
                  this.DepId = ''
                  this.snackColor = 'success';
                  this.mssg = 'Your registration has been successfully submitted. However, full access awaits document verification. Please complete it promptly to access our services fully.'
                  this.snackbar = true;
                  this.v$.$reset();
                })

              }
            }).catch(err => {
              console.log(err)
            })

          }
          else if (this.NoKids && !isNaN(this.NoKids)) {
            // console.log(isNaN(this.NoKids))
            this.v$.NoKids.$validate();
            if (this.v$.$errors.length == 0) {
              const checkDet = {
                phone: this.phone,
                email: this.email
              }
              this.CHECK_DUPLICATE_PHONE_EMAIL(checkDet).then(() => {
                if (this.duplicateCheck.response == 'failed') {
                  this.mssg = this.duplicateCheck.message;
                  this.snackColor = 'error';
                  return this.snackbar = true
                } else {
                  this.child = [];
                  for (let i = 0; i < parseInt(this.NoKids); i++) {
                    const obj = {
                      name: '',
                      dob: ''
                    }
                    this.child.push(obj);

                  }
                  // console.log(this.child)
                  this.step = 5
                }
              })



            }

          }
          else {
            const checkDet = {
              phone: this.phone,
              email: this.email
            }
            // console.log(checkDet)

            this.CHECK_DUPLICATE_PHONE_EMAIL(checkDet).then(() => {
              if (this.duplicateCheck.response == 'failed') {
                this.mssg = this.duplicateCheck.message;
                this.snackColor = 'error';
                return this.snackbar = true

              } else {
                this.step++

                this.Borough = this.boroughList;
                this.univer = this.universityLis;
              }
            })

          }

        }


      } else if (this.step == 2) {
        // this.v$.area.$validate();
        // this.v$.country.$validate();
        // this.v$.borough.$validate();
        this.v$.address.$validate();
        this.v$.zib.$validate();

        if (this.v$.$errors.length == 0) {
          if (this.type != 1) {
            this.studentId = null;
            this.university = null;
            this.ArrivedDate = null;
            this.startDate = null;
            this.EndDate = null;
            return this.step = 4
          }
          if (this.heveDependent || this.NoKids && !isNaN(this.NoKids) || this.ExpectedDate) return this.step++
          // console.log('heehee')
          const groupCheck = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_BASE_URL}members/checksame_address`,
            params: {
              houseno: this.country,
              road: this.address,
              zip: this.zib
            }
          })
          // console.log(groupCheck.data)
          if (groupCheck.data.response === 'failed') {
            this.step++
          } else {
            this.dialogBox = true
          }

          // this.step++
        }
        //  this.step++
      } else if (this.step == 3) {

        this.v$.studentId.$validate();
        this.v$.university.$validate();
        this.v$.ArrivedDate.$validate();
        this.v$.startDate.$validate();
        this.v$.EndDate.$validate();


        if (this.v$.$errors.length == 0) {
          this.step++
        }


      }
    },
    noGroup() {
      this.grp_err_text = "Please Add Another Address";
      this.dialogBox = false;
      this.v$.country.$reset();
      this.v$.address.$reset();
      this.v$.zib.$reset();
      this.country = '';
      this.zib = '';
      this.address = '';
    },
    async submitMemeber() {
      this.v$.deitryRequirment.$validate();
      this.v$.eggs.$validate();
      this.v$.collectionDay.$validate();
      if (this.v$.$errors.length == 0) {
        if (!this.select) {
          this.snackColor = 'error';
          this.mssg = 'Please Agree to Terms and Conditions'
          this.snackbar = true;

        } else {
          const payload = {
            statusId: 1,
            title: this.titles,
            firstname: this.firstName,
            lastname: this.secondName,
            phone: this.phone,
            email: this.email,
            membersroleId: this.type,
            dependentname: this.DependentName,
            noofkids: this.NoKids,
            area: 0,
            country: this.formAddress.county,
            road: this.address,
            borough: this.borough,
            address: this.formAddress.formatted_address.join(),
            houseno: this.country,
            zip: this.zib,
            studentid: this.studentId,
            universityId: this.university,
            arrivedin: this.ArrivedDate,
            coursestart: this.startDate,
            courseend: this.EndDate,
            diettype: 1,
            dietTypeId: this.deitryRequirment,
            eggs: this.eggs,
            preferredcollectionday: this.collectionDay,
            dependentname: this.dependentName,
            dependentphone: this.dependentPhone,
            dependentemail: this.dependentemail,
            membertype: this.memberType

            // expected: this.ExpectedDate
          }
          if (this.type > 3) {
            payload.membersroleId = 2
          }

          if (this.NoKids && !isNaN(this.NoKids)) {
            payload.children = this.child
            payload.membersroleId = 3
            if (this.type != 1) {
              payload.membersroleId = 2
            }
          }
          if (this.NoKids == 'Expected') {
            payload.noofkids = null;
            payload.expected = this.ExpectedDate;
            payload.membersroleId = 3
          }
          // this.$emit('add_memeber', payload)
          // console.log(payload)
          await this.ADD_MEMBER(payload).then(() => {
            this.titles = ''
            this.firstName = ''
            this.secondName = ''
            this.phone = ''
            this.email = ''
            this.type = ''
            this.DependentName = ''
            this.NoKids = null
            this.heveDependent = null
            this.area = ''
            this.country = ''
            this.borough = ''
            this.address = ''
            this.zib = ''
            this.studentId = ''
            this.university = ''
            this.ArrivedDate = ''
            this.startDate = ''
            this.EndDate = ''
            this.deitryRequirment = ''
            this.eggs = ''
            this.collectionDay = ''
            this.dependentName = ''
            this.dependentPhone = ''
            this.dependentemail = ''
            this.select = ''
            // this.step = 1;

            this.v$.$reset();
            this.snackColor = 'success';
            this.mssg = 'Your registration has been successfully submitted. However, full access awaits document verification. Please complete it promptly to access our services fully.'
            this.snackbar = true;
            setTimeout(() => {
              this.snackbar = false;
              this.step = 1
            }, 5000)
          }).catch(err => {
            console.log(err)
            this.snackColor = 'error';
            this.mssg = err;
            this.snackbar = true;
          })

        }

      }
    },

    goBack() {
      if (this.step == 5) {
        this.step = 1

      }
      else if (this.step == 4 && this.type != 1) {
        this.step = 2
      }
      else {
        this.step--
      }
    },
    set_role() {
      this.Role = this.memberRole;
    },
    set_borough() {

    },
    submitMemebers() {
    }
  },

  beforeMount() {
    const query = {
      size: 6
    }

    // this.GET_MEMBER_LIST();
    // this.GET_MEMBER_ROLE();
    // this.GET_BORUGH_LIST(query);
    // this.GET_UNIVERSITY_LIST(query);




    // boroughList
    // universityLis

  },
  // beforeCreate(){
  //   document.body.className
  // }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&family=Montserrat:wght@400;500;600;800&family=Sora:wght@400;500&display=swap');
</style>
<style >
/* #date{
  border: 2px solid #0097e6;
  background: none;
  display: block;
  margin: 20px auto;
  padding: 15px 45px;
  width: 200px;
  outline: none;
  color: white;
  border-radius: 25px;
  text-align: center;
  transition: 250ms width ease, 250ms border-color ease;
}
/************** 
   On Hover
***************/
/* #date:hover{
  width: 210px;
} */
/************** 
   On Focus
***************/
/* #date:focus{
  width: 230px;
  border-color: #6fb98f;
} */



.v-main {
  background: #719991;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

input {
  outline: none !important;
  color: grey;
  /* -webkit-appearance: none; */
}

/* @media screen and (max-device-width: 740px) { */
.dt {
  border-bottom: 1px solid #ababab;
  background: white;
  color: black;
}

/* } */
@media screen and (max-device-width: 640px) {
  .v-main {
    background: none;
  }
}

.v-v-overlay__content v-autocomplete__content {
  max-height: 300px;
}
</style>
<style scoped>
.v-card-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: Montserrat, sans-serif !important;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif */
}

.date {
  align-items: self-end;
}

.v-card {
  width: 70%
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 700px) {

  /*Big smartphones [426px -> 600px]*/
  .v-card {
    width: 82%
  }
}

@media only screen and (max-width: 425px) {

  /*Small smartphones [325px -> 425px]*/
  .v-card {
    width: 82%
  }
}
</style>
