<template>
    <h1 class="text-center mt-8" >Page Not Found    :(</h1>
</template>

<script>
export default {
name: 'ErrorPage'
}
</script>

<style scoped>
    h1{
        /* color: red ;  */
        font-family: Montserrat, sans-serif !important;
        font-weight: 600;
    }
/* .v-main {
  background: none;
} */
</style>
  