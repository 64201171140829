import axios from 'axios'
export default {
    namespaced: true,
    state: {
        eventList: {},
        eventTotalPage: ''
        //  editServicetValue:{}
    },
    mutations: {
        SET_EVENT_LIST( state, data){
            state.eventList = data
        },
        SET_EVENT_TOTAL_PAGE( state, data ){
            state.eventTotalPage = data
        }
      
    },
    actions: {
         /* eslint-disable */
        async GET_EVENT_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}events/list_of_events`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options)
             commit('SET_EVENT_LIST', data.data.data)
             console.log(state.eventList)
             commit('SET_EVENT_TOTAL_PAGE', data.data.totalPages)
        },
        async ADD_EVENTS({ commit }, payload){
            try {

                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const  add_events = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}events/add_event`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
             console.log(add_events);

            } catch (error) {
                console.log(error);
            }
            

        },
      
        async DELETE_EVENT({ commit }, payload){
            // console.log(payload)
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                const deleteEvent = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}events/delete_event/${ payload.id }`,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                console.log(deleteEvent)

            } catch (error) {
                console.log(error)
            }
        } ,
       
        async UPDATE_EVENT({ commit }, payload){
            try{
                 const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}events/update_events/${ payload.id }`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                 console.log(update)

            }catch(error){
                console.log(error)
            }
        },
       
    },
    getters: {
    
        
    }

}