<template>
    <v-container class="">
        <v-card rounded="0" class="pa-lg-10 pa-md-10 pa-sm-5 card1" elevation="0">
            <v-row justify="center" class="mt-4">
                <v-col cols="12" lg="6" md="12" sm="12" class="">
                    <div class="text-center" style="width: 100%">
                        <v-img :src="logo" class="ma-auto mt-" height="100" width="210" cover=""></v-img>
                    </div>
                    <v-card-title class="text-h5 text-sm-h6 mt-10 ">
                        Newham Community Project
                    </v-card-title>
                    <v-card-text class=" mt-2 pb-1">
                        Newham Community Project have set up an emergency support helpline, and are offering
                        appointments for anyone in need of help for problems at work, immigration concerns,
                        housing/landlord issues, and university problems.
                    </v-card-text>
                    <v-card-text class="">
                        The appointments are being held every Monday at St Stephen's Children's Centre, East Ham, E6 1AS
                        - you can only be <span style="font-weight: 600;"> seen by appointment only!</span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-2 py-0">
                        PLEASE DO NOT COMPLETE THIS FORM IF YOU ARE LOOKING FOR A JOB OR FOR ACCOMMODATION OR FINANCIAL
                        HELP - THIS FORM IS NOT FOR THESE PROBLEMS!
                    </v-card-text>
                    <v-card-text class=" my-2">
                        If you have any existing problems and would like to discuss, take advice or help from experts in
                        the different areas, then only please complete this form.
                    </v-card-text>

                    <!-- </v-card> -->
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="12" class="">

                    <v-window v-model="step" :touch="false" class="pt-2">
                        <v-window-item :value="1">
                            <span v-if="v$.name.$error" style="color: red">
                                Full Name is required
                            </span>
                            <v-text-field label="Full Name" density="compact" v-model="name" variant="outlined">
                                <template v-slot:label>
                                    <div>Full Name <span class="red">*</span></div>
                                </template>
                            </v-text-field>
                            <span v-if="v$.email.$error" style="color: red">
                                {{ v$.email.$errors[0].$message }}
                            </span>
                            <v-text-field label="Email" density="compact" v-model="email" variant="outlined">
                                <template v-slot:label>
                                    <div>Email <span class="red">*</span></div>
                                </template>
                            </v-text-field>
                            <span v-if="v$.num.$error" style="color: red">
                                {{ v$.num.$errors[0].$message }}
                                <!-- Contact Number is required -->
                            </span>
                            <v-text-field label="Contact Number" density="compact" v-model="num" variant="outlined">
                                <template v-slot:label>
                                    <div>Contact Number <span class="red">*</span></div>
                                </template>
                            </v-text-field>
                            <span v-if="v$.address.$error" style="color: red">
                                <!-- {{ v$.address.$errors[0].$message }} -->
                                Address is required
                            </span>
                            <v-textarea variant="outlined" label="Full Address including postcode" v-model="address"
                                outlined rows="2" no-resize row-height="25" shaped>
                                <template v-slot:label>
                                    <div>Full Address including postcode<span class="red">*</span></div>
                                </template>
                            </v-textarea>
                            <span v-if="v$.imigrationStatus.$error" style="color: red">
                                <!-- {{ v$.address.$errors[0].$message }} -->
                                Please Add your Immigration Status
                            </span>
                            <v-text-field label="" density="compact" hide-details="" v-model="imigrationStatus"
                                variant="outlined">
                                <template v-slot:label>
                                    <div>Immigration Status - what visa do you have?
                                        <span class="red">*</span>
                                    </div>
                                </template>
                            </v-text-field>
                            <div>

                                <v-card-text class="px-0 pb-1 pt-5">
                                    <div v-if="v$.radios.$error" style="color: red;font-size: 16px;">
                                        <!-- {{ v$.address.$errors[0].$message }} -->
                                        Please Add your Accommodation type
                                    </div>
                                    <div>
                                        What type of accommodation do you live in?<span class="red">*</span>
                                    </div>
                                </v-card-text>
                                <v-radio-group v-model="radios">
                                    <v-radio color="#ef8204" label="House" value="House"></v-radio>
                                    <v-radio color="#ef8204" label="Flat" value="Flat"></v-radio>
                                    <v-radio color="#ef8204" label="One Room in shared accomodation"
                                        value="One Room in shared accomodation"></v-radio>
                                    <v-radio color="#ef8204" label="Two rooms in shared accomodation"
                                        value="Two rooms in shared accomodation"></v-radio>
                                    <span v-if="v$.others.$error" style="color: red;margin-left: 39px;">
                                        Maximum 60 characters
                                    </span>
                                    <v-radio color="#ef8204" label="" value="Other">
                                        <template v-slot:label>
                                            <v-text-field placeholder="other" hide-details="" v-model="others"
                                                variant="outlined" counter="" density="compact"
                                                @click="radios = 'Other'"></v-text-field>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </div>


                        </v-window-item>
                        <v-window-item :value="2">
                            <span v-if="v$.firstlang.$error" style="color: red">
                                <!-- {{ v$.subject.$errors[0].$message }} -->
                                Please Add your first language
                            </span>
                            <v-text-field label="" density="compact" v-model="firstlang" variant="outlined">
                                <template v-slot:label>
                                    <div>First language<span class="red">*</span></div>
                                </template>
                            </v-text-field>

                            <span v-if="v$.spkEng.$error" style="color: red">
                                Please select any value
                            </span>
                            <v-select :items="itemsSpkEng" variant="outlined" density="compact" v-model="spkEng"
                                clearable label="">
                                <template v-slot:label>
                                    <div>Do you speak English?<span class="red">*</span></div>
                                </template>
                            </v-select>

                            <span v-if="v$.selEmployment.$error" style="color: red">
                                Please select any value
                            </span>
                            <v-select :items="emp" item-title="title" item-value="val" variant="outlined"
                                density="compact" v-model="selEmployment" clearable label="">
                                <template v-slot:label>
                                    <div>Type Of Employment<span class="red">*</span></div>
                                </template>
                            </v-select>

                            <!-- <span v-if="v$.selEmployment2.$error" style="color: red">
                                Please select any value
                            </span> -->
                            <!-- <v-select :items="emp" item-title="title" item-value="val" variant="outlined"
                                density="compact" v-model="selEmployment2" clearable label="">
                                <template v-slot:label>
                                    <div>Are you a part-time employee? (less than 39 hrs/week)</div>
                                </template>
                            </v-select> -->

                            <span v-if="v$.subject.$error" style="color: red">
                                <!-- {{ v$.subject.$errors[0].$message }} -->
                                Please select your problem
                            </span>
                            <v-select :items="items" variant="outlined" density="compact" v-model="subject" clearable
                                label="What problem do you have?">
                                <template v-slot:label>
                                    <div>What problem do you have?<span class="red">*</span></div>
                                </template>
                            </v-select>
                            <span v-if="v$.fullDesc.$error" style="color: red">
                                <!-- {{ v$.fullDesc.$errors[0].$message }} -->
                                Please explain your problem
                            </span>
                            <v-textarea variant="outlined" label="Please explain your problem in detail" hide-details
                                no-resize v-model="fullDesc" outlined rows="5" row-height="25" shaped>
                                <template v-slot:label>
                                    <div>Please explain your problem in detail<span class="red">*</span></div>
                                </template>
                            </v-textarea>
                        </v-window-item>
                    </v-window>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="white" v-if="step === 2" class="mt-3" style="background: #323232;" height="40"
                            width="80" plain depressed rounded="0" @click="step = 1">
                            back
                        </v-btn>
                        <v-btn color="white" class="mt-3" style="background: #323232;" height="40" width="80" plain
                            depressed rounded="0" @click="submitForm">
                            <span v-if="step === 1"> Next</span>
                            <span v-else> Submit</span>
                        </v-btn>
                    </v-card-actions>

                    <!-- </v-card> -->
                </v-col>

            </v-row>
        </v-card>
        <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />
    </v-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import snackbarVue from '@/components/snackbar';
/* eslint-disable */
import axios from 'axios'
import { helpers, required, numeric, decimal, maxLength, email } from "@vuelidate/validators";

export default {
    components: {
        snackbarVue
    },
    data() {
        return {
            v$: useVuelidate(),
            step: 1,

            snackbar: false,
            mssg: '',
            snackColor: 'green',

            logo: require('@/assets/newham.jpeg'),
            items: ['Employment', 'Landlord/property housing issues', 'Immigration', 'Other'],
            itemsSpkEng: ['Yes', 'No', 'A little'],
            // emp: [
            //     {
            //         title: 'yes',
            //         val: true
            //     },
            //     {
            //         title: 'No',
            //         val: false
            //     }
            // ],
            emp: [
                {
                    title: 'Full time employee (40 hrs/week)',
                    val: true
                },
                {
                    title: 'Part time employee (less than 39 hrs/week)',
                    val: false
                }
            ],

            name: '',
            email: '',
            num: '',
            address: '',
            subject: '',
            fullDesc: '',
            radios: '',
            others: '',
            imigrationStatus: '',
            firstlang: '',
            spkEng: '',
            selEmployment: '',
            selEmployment2: '',

        }
    },
    validations() {
        return {
            name: { required },
            email: { email, required },
            num: { required, numeric, maxLength: maxLength(12) },
            others: { maxLength: maxLength(60) },
            address: { required },
            imigrationStatus: { required },
            radios: { required },

            subject: { required },
            fullDesc: { required },
            firstlang: { required },
            spkEng: { required },
            selEmployment: { required },
            // selEmployment2: { required },
        }
    },
    methods: {
        async submitForm() {
            if (this.step === 1) {
                this.v$.name.$validate();
                this.v$.email.$validate();
                this.v$.num.$validate();
                this.v$.address.$validate();
                this.v$.imigrationStatus.$validate();
                this.v$.radios.$validate();
                if (this.radios === 'Other') {

                    this.v$.others.$validate();

                }

                // console.log(this.v$)
                if (this.v$.$errors.length == 0) {
                    this.step = 2
                }

            }
            else if (this.step == 2) {
                this.v$.subject.$validate();
                this.v$.fullDesc.$validate();
                this.v$.firstlang.$validate();
                this.v$.spkEng.$validate();
                this.v$.selEmployment.$validate();
                // this.v$.selEmployment2.$validate();
                if (this.v$.$errors.length == 0) {
                    const payload = {
                        name: this.name,
                        email: this.email,
                        mobile: this.num,
                        address: this.address,
                        subject: this.subject,
                        description: this.fullDesc,
                        supportStatusId: 1,
                        accommodation: this.radios,
                        immigrationStatus: this.imigrationStatus,
                        firstLanguage: this.firstlang,
                        english: this.spkEng,
                        type: this.selEmployment,
                        // partEmployment: this.selEmployment2,
                    }
                    if (this.radios === 'Other') {
                        payload.accommodation = 'Other' + ' ' + this.others
                    }
                    // console.log(payload)
                    await axios({
                        method: 'post',
                        url: `${process.env.VUE_APP_BASE_URL}support/send_request_byform`,
                        data: payload
                    }).then((result) => {
                        console.log(result)

                        this.snackbar = true;
                        this.mssg = 'Form Submitted Successfully';
                        this.v$.$reset();
                        this.name = '';
                        this.num = '';
                        this.email = '';
                        this.address = '';
                        this.subject = '';
                        this.fullDesc = '';
                        this.radios = '';
                        this.imigrationStatus = '';
                        this.firstlang = '';
                        this.spkEng = '';
                        this.selEmployment = '';
                        this.selEmployment2 = '';
                        this.others = '';
                        this.step = 1

                    }).catch(err => {
                        console.log(err)
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.red {
    color: red
}

.v-card {

    font-family: Montserrat, sans-serif !important;
    width: 75%;

}

.card1 {
    height: auto;
    border-right: 1px solid #efefef;
}

.card2 {
    padding-top: 54px !important;
    height: auto;


}

/* .v-row {
    height: 100vh;
    align-items: center; 
     width: 75%
}  */

@media only screen and (max-width: 1278px) {

    /*Tablets [601px -> 1200px]*/
    .card1 {
        /* height: 490px; */
        margin: auto;

        border-right: none;
        /* border-bottom: 1px solid #efefef; */

    }
}

@media only screen and (max-width: 700px) {

    /*Big smartphones [426px -> 600px]*/
    .v-row {
        width: 100%;
        margin: 0px !important
    }

    .card1 {
        /* height: 490px; */
        margin: auto;
        width: 100%;
        border-right: none;
        /* border-bottom: 1px solid #efefef; */

    }
}
</style>
<style>
.v-label {
    font-size: 14px;
}

.v-text-field--outlined fieldset {
    color: red !important;
}
</style>